<template>

    <v-dialog v-model="showDialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span>
                    Si la orden ya tiene una etiqueta esta acción la eliminaría y generaría una nueva.<br/>
                    ¿Desea continuar?
                </span>
            </v-card-title>
            <v-card-actions>
                <v-btn color="primary" text @click="showDialog = false" > Cancelar </v-btn>
                <v-btn color="success" text @click="sendForm" > Aceptar </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
import EventBus from '@/event-bus';
export default {
    data(){
        return {
            valid:true,
            showDialog: false,
            shipment: {
                id: undefined,
            },
        }
    },
    created(){
        var vm = this;
        EventBus.$on('openDialogRegenerarEtiqueta', (shipment) => {
            vm.shipment = shipment;
            vm.showDialog = true;
        });
    },
    methods: {
        sendForm(){
            console.log('se deberia enviar el formulario');
            var localShipment = this.shipment;
            localShipment.force_label_regenerate = true;
            console.log(localShipment);
            var vm = this;
            this.axios({
                url: 'shipment/regenerate_label',
                method: 'POST',
                data: localShipment
            }).then( () => {
                vm.showDialog = false;
                vm.$emit('success');
            }).catch( error => {
                console.log(error);
            });
        },
        
    },
}
</script>