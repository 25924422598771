<template>

    <v-dialog v-model="showDialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span>Modificar Número de seguimiento - Envío #{{shipment.id}}</span>
            </v-card-title>
            <v-card-text>
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                >

                    <v-text-field
                        v-model="shipment.shipping_number"
                        :rules="nameRules"
                        label="Número de seguimiento"
                        required
                    ></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
import EventBus from '@/event-bus';
export default {
    data(){
        return {
            valid:true,
            showDialog: false,
            shipment: {
                id: undefined,
            },
            nameRules: [
                v => !!v || 'El Campo es requerido',
            ],
        }
    },
    created(){
        var vm = this;
        EventBus.$on('openDialogModificarNroSeguimiento', (shipment) => {
            vm.shipment = shipment;
            vm.showDialog = true;
        });
    },
    methods: {
        sendForm(){
            console.log('se deberia enviar el formulario');
            var vm = this;
            this.axios({
                url: 'shipment/shipping_number',
                method: 'PUT',
                data: this.shipment
            }).then( () => {
                vm.showDialog = false;
                vm.$emit('success');
            }).catch( error => {
                console.log(error);
            });
        },
        
    },
}
</script>